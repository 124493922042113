export const QTPET = {
  loginApi: "/users/userLogin",
  //password Api's
  forgotPassword: "/users/forgetPassword",
  changePassword: "/users/changePassword",

  //Listing Api's
  getCustomerList: "/superAdmin/getUsersList",
  getUserList: "/admin/allUserList",
  getDeliveryStaff: "/superAdmin/getUsersList",
  getQuoteRequestList: "/superAdmin/allQuoteList",
  getBookingList: "/booking/v2/getBookingList",
  getPaymentHistoryList: "/superAdmin/paymentHistoryForAdmin",
  getAllUserList: "/superAdmin/getAllUsersList",
  loyalMember: "/superAdmin/convertToLoyalMember",

  //Details Api's
  profileDetail: "/superAdmin/getProfileDetails",
  getAdminProfileDetails: "/users/getProfileDetails",
  getDashboardData: "/superAdmin/dashboard",
  getBookingDetails: "/booking/v2/getBookingDetails",

  //Delete/Suspend Api's
  suspendAccount: "/superAdmin/changeStatus/",
  suspendAccountDelivery: "/superAdmin/changeStatus",

  // payment
  cardList: "/payment/cardList?language=en",

  //Notification
  NotififyUser: "/superAdmin/notifyAllUsers",
  NotificationList: "/users/getNotificationList",
  ReadNotification: "/users/readNotification?",
  UnReadCount: "/users/unreadNotificationCount",
  //Promo Code API's
  addPromoCode: "/promocode/addPromocode",
  getPromoCodeList: "/promocode/getPromoCodelist",
  deletePromoCode: "/promocode/deletePromoCode",
  getPromoCodeDetails: "/promocode/getPromoCodeDetails",

  // chat
  getChatHistoryList: "/chat/v2/getChatList",
  getMessageList: "/chat/v2/messageList",
  addNewUser:"chat/v2/addEmailInChat",
  getChatMemberList: "/chat/addNewMemberList",
  addMember: "/chat/addNewMember",
  // superAdmin
  getRecording: "/superAdmin/getBookingCallRecording",
  getRequestList: "/superAdmin/requestListForBooking",
  approveNannyRequest: "/superAdmin/approveNannyRequest",
  updateNannyDocStatus: "/superAdmin/updateNannyDocsStatus",
  getNannyMonthlyRevenue: "/superAdmin/getNannyMonthlyRevenue",
  selectNanny:"/booking/v2/selectNanny",
  reassignBookingtoNanny:"/superAdmin/reassignBookingToNanny",
  markAsPaid:"/superAdmin/markPaid"
};

export const API_RESPONSE = {
  SUCCESS: 1,
  FAILURE: 0,
};
