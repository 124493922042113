/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  // Icon,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import theme from "../../../theme";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  clearReuestList,
  getRequestListDetail,
  // getRequestListdata,
  getbookingList,
  markAsPaid,
} from "../../../store";
import Loader from "../../../components/loader/loader";
import AdminInput from "../../../components/adminInput";
import moment from "moment/moment";
import AdminDatePicker from "../../../components/adminDatePicker";
import AdminSelect from "../../../components/adminSelect";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { appColors } from "../../../theme/colors";
import AlertDialog from "../../../components/dialog/alertDialog/alertDialog";
import ViewdRequestDialog from "../../../components/viewRequestDialog";
import ViewAssignDetails from "../../../components/ViewAssignDetailDialog";
import ReactPlayer from "react-player";
import ReactHlsPlayer from "react-hls-player";
import AudioPlayer from "./audioPlayer";
import AdminCheckbox from "../../../components/adminCheckbox";
import { use } from "react";

export default function index() {
  const playerRef = useRef();

  var checkDate = new Date();
  var newDate = moment(checkDate).format("YYYY-MM-DD");
  var page = 1;
  var perPage = 10;
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [confirmation,setConfirmation]=useState(false)
  const [showLoader, setShowLoader] = useState(false);
  const [paidDetails,setPaidDetails]=useState({})

  const [pagecheck, setPageCheck] = useState(1);
  const [statusValue, setStatusValue] = useState("");
  const [startDate, setStartDate] = useState("dd/mm/yyyy");
  const [endDate, setEndDate] = useState("dd/mm/yyyy");
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [startDateForSend, setStartDateForSend] = useState("");
  const [endDateForSend, setEndDateForSend] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewDetailAlertDialog, setViewDetailAlertDialog] = useState(false);

  const { bookingData, totalCount, isLoading } = useSelector(
    (state) => state.userSlice
  );
  const { getRequestListData, profileData } = useSelector(
    (state) => state.userSlice
  );

  var mobiletheme = useTheme();
  const isMobile = useMediaQuery(mobiletheme.breakpoints.down("md"));
  const [viewAssignDialog, setViewAssignDialog] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setShowLoader(true);
    bookingList();
  }, []);

  useEffect(() => {
    if (bookingData?.length > 0) {
      setShowLoader(false);
    }
  }, [bookingData]);

  const handleStartDate = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD");
    setStartDate(value);
    setStartDateForSend(valDate);
  };

  const handleEndDate = (value, numberOfRows) => {
    const valDate = moment(value).format("YYYY-MM-DD");

    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(
      valDate === "dd/mm/yyyy" || valDate === "Invalid date"
        ? "dd/mm/yyyy"
        : startDate
    );
    setEndDateForSend(valDate === "Invalid date" ? "" : valDate);

    var data = {
      language: "en",
      userId: "",
      bookingStatus: statusValue,
      search: searchValue,
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      startDate:
        valDate == "Invalid date" || valDate == "" ? "" : startDateForSend,
      endDate: valDate !== "Invalid  date" ? valDate : "",
    };
    let isSearch = searchValue ? true : false;
    dispatch(getbookingList(data, isSearch, setShowLoader));
  };
  const today = new Date().toISOString().split("T")[0];
  const bookingList = (page, numberOfRows, search) => {
    endDateForSend === "Invalid date" && setEndDateForSend("dd/mm/yyyy");
    var data = {
      language: "en",
      userId: "",
      bookingStatus: statusValue,
      search: search,
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      startDate: "",
      endDate: "",
    };
    let isSearch = search ? true : false;
    dispatch(getbookingList(data, isSearch, setShowLoader));
  };

  const dateData = [
    { id: "day", label: "Day" },
    { id: "week", label: "Week" },
    { id: "month", label: "Month" },
    { id: "lastDay", label: "Last Day" },
    { id: "lastWeek", label: "Last Week" },
    { id: "lastMonth", label: "Last Month" },
  ];

  const statusData = [
    { id: 0, label: "New order" },
    { id: 1, label: "Completed" },
    { id: 2, label: "On going" },
    { id: 3, label: "Cancel" },
  ];

  useEffect(() => {
    bookingList();
  }, [statusValue]);

  const clearFilter = () => {
    setStatusValue("");
    setStartDate("dd/mm/yyyy");
    setEndDate("dd/mm/yyyy");

    clearSearch();
    toast.success("Clear All Successfully");
  };

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10, 15, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;

        bookingList(page, "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        bookingList(page, "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      bookingList(page, numberOfRows, "");
    },
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length >= 1) {
      bookingList(page, perPageRows, e.target.value);
    } else if (e.target.value.length === 0) {
      bookingList(page);
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    bookingList(page, perPage, "");
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(false);
    setViewDetailAlertDialog(false);
    dispatch(clearReuestList());
  };
  const handleClick = (event, tableMeta) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(tableMeta?.rowIndex);
  };

  const handleClickOpen = (id) => {
    var requestParams = {
      _id: id,
    };
    dispatch(getRequestListDetail(requestParams, setShowLoader));
    setViewDetailAlertDialog(true);
  };
  const handleAssign = () => {
    setViewAssignDialog(true);
  };
  const handleMarkAsPaidConfirmation=(id,nannyId,status)=>{
setPaidDetails((val)=>({
  ...val,
  id:id,
  nannyId:nannyId,
  status:status
}))
    setConfirmation(true)
  }
  const handleMarkAsPaid = () => {
    let data = {
      bookingId: paidDetails?.id,
      nannyId: paidDetails?.nannyId,
      paidStatus: paidDetails?.status,
    };
    dispatch(markAsPaid(data, setShowLoader));
  
  };
  const assignData = bookingData?.[selectedRowIndex];

  const columns = [
    {
      name: "bookingNumber",
      label: "Booking Id",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{value ? value : "-"}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Booking Date",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {" "}
                {moment(value ? value : "-").format("MM/DD/YYYY")}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "sellerFirstName",
      label: "Seller",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value
                    ? bookingData[tableMeta.rowIndex].sellerFirstName +
                      " " +
                      bookingData[tableMeta.rowIndex].sellerLastName
                    : ""}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },

    {
      name: "petFirstName",
      label: "Pet Owner",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box sx={{ width: "120px" }}>
              <Tooltip title={value}>
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {value
                    ? bookingData[tableMeta.rowIndex].petFirstName +
                      " " +
                      bookingData[tableMeta.rowIndex].petLastName
                    : "-"}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
    },

    {
      name: "breed",
      label: "Breed",

      options: {
        filter: true,
        sort: false,
      },
      customBodyRender: (value) => {
        return (
          <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
        );
      },
    },
    {
      name: "assignedToDelivered",
      label: "Assigned To",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value ? value.firstName + " " + value.lastName : "-"}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "deliveryDate",
      label: "Delivery Date",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {moment(value ? value : "-").format("MM/DD/YYYY")}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "pickAirpot",
      label: "Pickup airport",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{value.airpotName}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "deliveredAirpot",
      label: "Delivery airport",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{value.airpotName}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "markAsPaid",
      label: "Mark as Paid",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value,tableMeta) => {
          const id = bookingData[tableMeta.rowIndex]?._id;
          const nannyId=bookingData[tableMeta.rowIndex]?.assignedToDelivered?._id
      
          return (
            <Grid>
              <AdminCheckbox
                checked={value}
                disable={value===true && true}
                handleChange={(val) => {
                  handleMarkAsPaidConfirmation(id,nannyId,val)
               
                }}
              />
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value === 0 ? (
                  <Box
                    sx={{
                      background: appColors.topbar,
                      textAlign: "center",
                      borderRadius: 0.4,
                      p: 0.1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: appColors.whiteColor,
                        fontSize: 13,
                        fontWeight: 400,
                      }}
                    >
                      New order
                    </Typography>
                  </Box>
                ) : value === 1 ? (
                  <Box
                    sx={{
                      background: appColors.green,
                      textAlign: "center",
                      borderRadius: 0.4,
                      p: 0.4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: appColors.whiteColor,
                        fontSize: 13,
                        fontWeight: 400,
                      }}
                    >
                      Completed
                    </Typography>
                  </Box>
                ) : value === 3 ? (
                  <Box
                    sx={{
                      background: "red",
                      textAlign: "center",
                      borderRadius: 0.4,
                      p: 0.4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: appColors.whiteColor,
                        fontSize: 13,
                        fontWeight: 400,
                      }}
                    >
                      Cancel
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      background: appColors.orange,
                      textAlign: "center",
                      borderRadius: 0.4,
                      p: 0.4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: appColors.whiteColor,
                        fontSize: 13,
                        fontWeight: 400,
                      }}
                    >
                      Pending
                    </Typography>
                  </Box>
                )}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "totalFlightRequest",
      label: "Request",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = bookingData[tableMeta.rowIndex]?._id;

          return (
            <div>
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    color: "lightGrey",
                    backgroundColor: "grey",
                  },
                }}
                badgeContent={value ? value : "0"}
              >
                <Button
                  onClick={() => handleClickOpen(id)}
                  sx={{
                    background: "transparent",
                    color: appColors.blackColor,
                    height: 25,
                    p: 0.5,
                    border: "1px solid black",
                    fontSize: 13,
                    textTransform: "none",
                    fontWeight: 400,
                  }}
                >
                  View
                </Button>
              </Badge>{" "}
            </div>
          );
        },
      },
    },
    {
      name: "_id",
      label: "Action",

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = bookingData[tableMeta.rowIndex]?.userId;
          return (
            <Grid>
              <Typography>
                <Button>
                  <Icon
                    icon="ph:dots-three-circle-vertical-fill"
                    color={appColors.topbar}
                    width="30"
                    height="30"
                    onClick={(event) => handleClick(event, tableMeta)}
                  />
                </Button>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClick={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton onClick={handleAssign}>
                        <ListItemIcon>
                          <Icon
                            icon="ri:eye-fill"
                            color="#21618C"
                            width="25"
                            height="25"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="View Details"
                          style={{ marginLeft: "-1rem" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popover>
              </Typography>
            </Grid>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Bookings</title>
      </Helmet>
      <Box sx={{ background: "white", mt: isMobile ? -2 : 0, p: 2 }}>
        <Grid container sx={{ justifyContent: "space-between", mt: 1 }}>
          <Grid item xs={11}>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
              <strong>Bookings</strong>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box
              sx={{
                mt: 1,
                display: isMobile ? "block" : "none",
                cursor: "pointer",
              }}
              onClick={clearFilter}
            >
              <Icon icon={"system-uicons:reset"} width={"35"} height="35" />
            </Box>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={isMobile ? 12 : 2.8}>
            <AdminInput
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  bookingList(page);
                }
              }}
              title="Search by name"
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    style={{ cursor: "pointer" }}
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 2.8}>
            <AdminInput
              title="Start Date"
              min={startDate !== "dd/mm/yyyy" ? startDate : ""}
              max={today} // Set the maximum date to today
              onKeyDown={(e) => e.preventDefault()}
              type="date"
              onChange={(e) => handleStartDate(e.target.value)}
              value={startDate || ""}
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 2.8}>
            <AdminInput
              title="End date"
              type={"date"}
              max={endDate != "dd/mm/yyyy" ? endDate : newDate}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => handleEndDate(e.target.value)}
              value={endDate || ""}
            />
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 2.8}
            sx={{ display: "flex", alignItems: "end" }}
          >
            <AdminSelect
              title="Status"
              menuData={statusData}
              value={statusValue}
              onChange={(val) => setStatusValue(val)}
            />
          </Grid>
        </Grid>
      </Box>

      <div className="App wrapper">
        {isLoading ? (
          <>
            <Grid
              container
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)",
                mt: 2,
                height: 300,
                backgroundColor: "white",
              }}
            >
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </>
        ) : (
          <>
            {bookingData && bookingData.length > 0 ? (
              <Grid container sx={{ width: "auto" }}>
                <Grid xs={12}>
                  <MUIDataTable
                    data={bookingData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                {!showLoader && (
                  <Grid
                    container
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      height: 300,
                      backgroundColor: "white",
                    }}
                  >
                    <Grid
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}
                      >
                        No Record Found
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </div>
      {showLoader && <Loader open={showLoader} text={"Please Wait"} />}
      {viewDetailAlertDialog && (
        <ViewdRequestDialog
          open={viewDetailAlertDialog}
          getRequestListData={getRequestListData}
          setShowLoader={setShowLoader}
          showRequestLoader={showLoader}
          profileData={profileData}
          handleClose={handleClose}
          title="Nanny Requests"
        />
      )}

      {viewAssignDialog && (
        <ViewAssignDetails
          open={viewAssignDialog}
          handleClose={() => {
            setViewAssignDialog(false);
          }}
          bookingData={bookingData}
          title="Assign Details "
          assignData={assignData}
        />
      )}
      {confirmation && (
        <AlertDialog
          open={confirmation}
          text="Are you certain you want to mark this booking as paid ?"
          handleClose={()=>{
            setConfirmation(false);
          }}
          handleAgree={handleMarkAsPaid}
          sx={{ fontSize: 16 }}
        />
      )}
    </ThemeProvider>
  );
}
